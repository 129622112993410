<template>
  <div :class="['relative w-full bg-white z-50', freshDoc ? ' disabledFooter' : '']">
    <progress-bar
      @goToTime="goToTime"
      :currentTime="currentTime"
      :totalTime="totalTime"
    />
    <audio-controls
      @toggleState="toggleState"
      :isMobile="isMobile"
      :isPlaying="isPlaying"
      @backward="backward"
      @forward="forward"
      :showDeleted="showDeleted"
      :toggleDeleted="toggleDeleted"
      :editMode="editMode"
      :showAutopilotTooltip="showAutopilotTooltip"
      :removeAutopilotTooltip="removeAutopilotTooltip"
      :reduceNoise="reduceNoise"
      :touring="touring"
      :isOnboarding="isOnboarding"
      @toggleReduceNoise="toggleReduceNoise"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { tippyTourNoiseReduction } from '../../tippy-popovers.js'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'

export default {
  data () {
    return {
      autoReduced: false
    }
  },
  props: {
    playerLoadProgress: {
      type: Number,
      default: 0
    },
    totalTime: {
      type: Number,
      default: 0
    },
    showAutopilotTooltip: {
      type: Boolean,
      default: false
    },
    touring: {
      type: Boolean,
      default: false
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'audio-controls': () => import(/* webpackChunkName: "AudioControls" */'./mini-components/AudioControls.vue'),
    'progress-bar': () => import(/* webpackChunkName: "ProgressBar" */'./mini-components/ProgressBar.vue')
  },
  methods: {
    ...mapActions({
      toggleDeleted: 'editor/toggleShowDeleted'
    }),
    deleteAudio () {
      myProseEditor.deleteAudio()
    },
    restoreAudio () {
      myProseEditor.restoreAudio()
    },
    toggleReduceNoise () {
      this.autoReduced = false
      this.$emit('toggleReduceNoise')
      if (this.isPlaying) {
        this.toggleState(true)
        setTimeout(() => {
          this.toggleState(true)
        }, 10)
      }
    },
    backward: function () {
      let vm = this
      vm.$emit('backward')
    },
    forward: function () {
      let vm = this
      vm.$emit('forward')
    },
    goToTime (time) {
      this.$emit('goToTime', time)
    },
    removeAutopilotTooltip: function() {
      this.$emit('removeAutopilotTooltip')
    },
    toggleState() {
      this.$emit('toggleState')
    }
  },
  computed: {
    ...mapGetters({
      'currentTime': 'doc/currentTime',
      'isMobile': 'app/isMobile',
      'isPlaying': 'editor/isPlaying',
      'checkPoint3': 'editor/checkPoint3',
      'editMode': 'editor/editMode',
      'reduceNoise': 'editor/reduceNoise',
      'timeDeleted': 'editor/timeDeleted',
      'showDeleted': 'editor/showDeleted',
      'freshDoc': 'editor/freshDoc'
    })
  },
  watch: {
    checkPoint3 (val) {
      this.toggleReduceNoise()
      this.autoReduced = true
      tippyTourNoiseReduction()
    }
  }
}
</script>

<style lang="scss" scoped>
.disabledFooter {
  filter: grayscale(100%);
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
}

.invisible {
  visibility: hidden;
}

.shortcutsIcon {
  min-width: 2rem;
  mask: url('../../../../../../assets/icons/Keyboard.svg');
}
</style>
